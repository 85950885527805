import {Link} from 'react-router-dom';
//Layouts
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import PageTitle from './../layouts/PageTitle';

//Components
import FooterSingUp from './../components/FooterSingUp';

//images
import pic1 from './../assets/images/services/pic1.jpg';
import pic2 from './../assets/images/services/pic2.jpg';
import pic3 from './../assets/images/services/pic3.jpg';
import pic4 from './../assets/images/services/pic4.jpg';
import pic5 from './../assets/images/services/pic5.jpg';
import pic6 from './../assets/images/services/pic6.jpg';

const serviceData = [
    {image: pic1, title:'Broadband'},
    {image: pic2, title:'Solar'},
    {image: pic3, title:'Green energy'},
    {image: pic4, title:'Air source heat'},
    {image: pic5, title:'Electric car charging points'},
    {image: pic6, title:'Off peak power storage'},
];

function Services(){
    return(
        <>
            <Header />
            <div className="page-content bg-white">
                <PageTitle  title='Our Services'  parentPage='Home' childPage='Pages' />
                {/* <!-- Services --> */}
                <section className="content-inner bg-gray">
                    <div className="container">	
                        <div className="row">
                            {serviceData.map((data, ind)=>(
                                <div className="col-lg-4 col-md-6" key={ind}>
                                    <div className="content-box2 m-b30">
                                        <div className="dz-info">
                                            <h3 className="title">{data.title}</h3>
                                        </div>
                                        <div className="dz-media m-b30">
                                            <img src={data.image} alt="" />
                                        </div>
                                        <div className="dz-bottom">
                                        </div>
                                    </div>
                                </div>
                            ))}    
                        </div>
                    </div>            
                </section>                    

            </div>
            <Footer />    

        </>
    )
}
export default Services;