import React from 'react';
import Index from './pages/Index';	

//Css 
//import "./assets/vendor/animate/animate.css";	
import "./assets/css/style.css";



function App() {
	
	return (
		<div className="App">
			<Index />
		</div>	
	);
}

export default App;
