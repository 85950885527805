export const MenuListArray = [
    {
        title: 'Site',
        content: [
            {
                title: 'Home',
                to: './',					
            },
            {
                title: 'Services',
                to: './services',
                
            }
            
        ],
    },

]